import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Phone = ({
  color = '#00833d',
  width = '1.4rem',
  height = '1.4rem'
}) => {
  return (
    <Svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 216.000000 215.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <title>Call Customer Service</title>
      <G transform='translate(0.000000,215.000000) scale(0.100000,-0.100000)' fill={color}>
        <path
          d='M1072 2117 c-29 -30 -28 -62 3 -92 20 -20 34 -25 78 -25 125 0 298 -51 422 -123 146 -86 270 -223 346 -382 56 -119 78 -199 86 -320 5 -75 10 -93
             29 -112 29 -29 65 -29 92 0 63 67 -12 397 -135 596 -62 100 -224 262 -324 324 -148 92 -361 157 -511 157 -54 0 -68 -4 -86 -23z'
        />
        <path
          d='M310 1838 c-22 -7 -69 -46 -126 -102 -135 -135 -182 -247 -171 -415 8 -141 53 -230 220 -438 230 -288 500 -546 783 -750 133 -96 221 -127 354
            -125 133 1 238 47 362 155 111 98 142 173 113 273 -16 54 -28 70 -173 216 -172 175 -207 198 -296 198 -70 0 -105 -17 -173 -82 l-51 -51 -49 28 c-152 87
            -285 223 -357 363 l-17 34 50 52 c27 30 57 70 65 91 22 51 20 121 -5 175 -24 53 -282 318 -346 356 -49 29 -130 39 -183 22z'
        />
        <path
          d='M1036 1828 c-21 -29 -20 -61 2 -87 13 -17 36 -23 109 -31 154 -17 265 -66 371 -165 116 -108 189 -257 200 -409 4 -66 9 -84 28 -103 29 -29 54
             -29 89 -1 27 21 28 22 22 107 -11 156 -61 288 -157 414 -133 176 -368 297 -576 297 -64 0 -75 -3 -88 -22z'
        />
        <path
          d='M1046 1539 c-60 -47 -20 -112 76 -123 160 -17 284 -143 303 -307 6 -47 12 -63 32 -79 32 -25 54 -25 84 -1 21 17 24 27 22 78 -8 211 -169 398
             -381 442 -76 16 -106 14 -136 -10z'
        />
      </G>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
  margin-right: 0.4rem;
`

const G = styled.g`
  fill: ${props => props.color};
  stroke: none;
`
