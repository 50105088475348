import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, {css} from 'styled-components'
import { CTAButton } from '../Button/Button'
import { cartAction } from '../../../actions/cartAction'
import { updateCartFormAction } from '../../../actions/updateCartDataAction'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'
import Cookie from 'js-cookie'

export const AddToCartButton = ({
  product,
  quantity = 1,
  useMobileSize = false,
  classes,
  additionalAddToCartCode,
  additionalAfterAddToCartCode,
  displayATCBanner,
  isCarousel = false,
  updateCart = false,
  styles = css`margin-right: 1.2rem;`,
  ogChecked = false,
  ageVerifyRequired = false,
  useSimpleATCButton
}) => {
  const dispatch = useDispatch()
  const ageVerified = useSelector(state => state?.shared?.ageVerified)
  const ageVerifyCookie = !!Cookie.get('ageVerified')

  const clickHandler = (event) => {

    const carouselTitle = event?.target?.closest('[data-carousel-title]')?.dataset?.carouselTitle
    const carouselPodId = event?.target?.closest('[data-carousel-title]')?.dataset?.carouselPodId
    const pageName = (new URL(window.location.href)).pathname

    if (ageVerifyRequired && ageVerifyCookie === false) { // if verification IS required AND age NOT verified ex: CBD products via cookie not being present
      dispatch(sharedSetInstanceAction({
        name: 'ageVerifyModal',
        data: {
          additionalAddToCartCode: additionalAddToCartCode,
          updateCart: updateCart,
          partNumber: product.productPartNumber,
          quantity: quantity,
          displayATCBanner: displayATCBanner,
          ogChecked: ogChecked,
          additionalAfterAddToCartCode: additionalAfterAddToCartCode
        }
      }))
    }
    if ((ageVerifyRequired === false) || ageVerifyCookie || ageVerified) { // if age IS verified OR verification NOT required ex: almonds
      if (additionalAddToCartCode) {
        additionalAddToCartCode()
      }
      if (updateCart) {
        dispatch(updateCartFormAction({
          action: 'add',
          id: product.productPartNumber,
          qty: '1',
        }, additionalAfterAddToCartCode))
      } else {
        dispatch(cartAction(product, quantity, additionalAfterAddToCartCode, displayATCBanner, ogChecked, {
          HeadlineText: carouselTitle,
          PodID: carouselPodId,
          PageName: pageName,
          IsSuggestion: isCarousel
        }))
      }
    }
  }

  return (
    <CTAButton
      styles={!useSimpleATCButton ?
        styles :
        css`
          background: ${props => props.theme.white};
          color: ${props => props.theme.black};
          border: 1px solid ${props => props.theme.mediumGrey};
        `
      }
      type='submit'
      data-testid='addToCartButton'
      className={`d-block add-to-cart-button border-0 ${isCarousel ? 'shpSCtrack-scAddCarousel' : 'shpSCtrack-scAdd'} ${classes}`}
      onClick={clickHandler}
      data-product-id={product.productPartNumber}
      data-cost={product.productDiscountPrice}
      data-content={product.productName}
      rel='nofollow'
      data-cnstrc-btn='add_to_cart'
    >
      <ButtonText useMobileSize={useMobileSize}>Add to Cart</ButtonText>
    </CTAButton>
  )
}

const ButtonText = styled.span`
  display: block;
`
