/**
 * Created by taylor.houston on 9/24/2018.
 */
import { PRODUCT_DATA_GET, PROMOTION_DATA_GET, RECOMMENDATION_DATA_GET } from '../constants/searchConstants'
import {
  LOADING,
  LOADING_COMPLETE,
  APP_ERROR,
  APP_SET_DATA,
  APP_SET_DATA_RERENDER,
  APP_SET_DATA_COMPLETE
} from '../constants/appConstants'
import { FACET_GROUP_BUILD } from '../constants/facetGroupConstants'
import { PAGE_PULL, PAGE_SORT_PULL } from '../constants/pageConstants'
import { BREADCRUMB_UPDATE } from '../constants/breadcrumbConstants'

import axios from 'axios'

export function updateSearchDataAction () {
  return (dispatch, reduxStore) => {
    dispatch({ type: LOADING })
    return axios.get(`${window.location.origin}${reduxStore().router.pathname}${reduxStore().router.search}${(reduxStore().router.search.indexOf('?') > -1 ? '&' : '?')}json=true&items=24`)
      .then((response) => {
        updateSearchData(response.data, dispatch)
        dispatch({ type: APP_SET_DATA_RERENDER })
      })
      .catch(() => {
        dispatch({ type: LOADING_COMPLETE })
        dispatch({ type: APP_ERROR, payload: {} })
      })
  }
}

export function updateSearchData (data, dispatch) {
  dispatch({ type: LOADING_COMPLETE })
  dispatch({ type: PRODUCT_DATA_GET, payload: data.searchResultsDTO.adobeRecords })
  dispatch({ type: PROMOTION_DATA_GET, payload: data.promotionsDTO ? data.promotionsDTO.promotionsMap : [] })
  dispatch({ type: RECOMMENDATION_DATA_GET, payload: data.recommendationResultList})
  dispatch({ type: PAGE_PULL, payload: data.searchResultsDTO.pagesDTO })
  dispatch({ type: PAGE_SORT_PULL, payload: data.searchResultsDTO.sortDTOList })

  dispatch({ type: FACET_GROUP_BUILD, payload: { facetKeys: data.searchResultsDTO.facetKeys, facetsMap: data.searchResultsDTO.facetsMap, moreFacetMap: data.searchResultsDTO.moreFacetsMap } })
  dispatch({ type: BREADCRUMB_UPDATE, payload: data.searchResultsDTO.breadCrumbsDTO.breadCrumbsList })
  dispatch({ type: APP_SET_DATA,
    payload: {
      title: data.searchResultsDTO.title,
      keyword: data.searchResultsDTO.kw,
      searchId: data.searchResultsDTO.searchId,
      recordsFrom: data.searchResultsDTO.numRecordsFrom,
      recordsTo: data.searchResultsDTO.numRecordsTo,
      totalPageResults: data.searchResultsDTO.numRecordsTotal,
      didYouMean: data.searchResultsDTO.didYouMean[0] || '',
      topBanner: data.searchResultsDTO.promotionalContent || '',
      bottomBanner: data.searchResultsDTO.bottomContent || '',
      topMobileBanner: data.searchResultsDTO.mobilePromotionalContent || '',
      bottomMobileBanner: data.searchResultsDTO.mobileBottomContent || '',
      footerInfo: data.searchResultsDTO.seoFooter || '',
      h1: data.searchResultsDTO.h1 || '',
      filterData: data.searchResultsDTO.filterData || {},
      index: data.searchResultsDTO.noIndex || false
    }
  })
  dispatch({ type: APP_SET_DATA_COMPLETE })
}
