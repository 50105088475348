import {
  PDP_SET_DATA_COMPLETE,
  SET_REVIEWS_PAGE,
  SET_REVIEWS_PAGES,
  TAB_LOADING,
  UPDATE_ANSWERS_TOTAL,
  UPDATE_IS_FAQ,
  UPDATE_PRODUCT_DETAILS,
  UPDATE_PRODUCT_DETAILS_FAVORITES,
  UPDATE_QUESTIONS_TOTAL
} from '../../constants/productDetailConstants'
import { REVIEWS_START } from '../../constants/reviewConstants'
import { updateURLsAction } from '../updateURLsAction'
import { formSubmitAction, formUpdateAction } from '../formActions'
import { CLEAR_OOS, OG_UPDATE_CHECKED, SHARED_LOADING_END, SHARED_LOADING_START } from '../../constants/sharedConstants'
import { showNotificationWithTimeout } from '../notificationActions'
import axios from 'axios'
import { CLEAR_QUESTION_ANSWER, QUESTION_ANSWER_START } from '../../constants/questionAnswerConstants'
import { CHECKOUT_COMMENTS_START, CLEAR_CHECKOUT_COMMENTS } from '../../constants/checkoutCommentsConstants'
import { CLEAR_FAQ, FAQ_START } from '../../constants/productFAQConstants'
import { UPDATE_CART_PRODUCT_DETAILS_FAVORITES } from '../../constants/shoppingCartConstants'

export const sizeFormSuccessAction = (data, urlParameters, callBack) => {
  return (dispatch) => {
    dispatch(updateProductDetailData(data))
    dispatch(updateURLsAction('/' + data.adobeRecord.longURL + urlParameters))
    dispatch(resetProductQaFaq(data))
    dispatch(getFAQs(data.adobeRecord.productPartNumber))
    dispatch(getQAAndComments(data.adobeRecord.productPartNumber))
    dispatch({ type: OG_UPDATE_CHECKED, payload: false })
    callBack()
  }
}

export const updateProductDetailData = (data) => {
  return dispatch => {
    dispatch({ type: CLEAR_OOS })
    dispatch({ type: UPDATE_PRODUCT_DETAILS, payload: data.adobeRecord })
    dispatch(formUpdateAction({ form: 'reviews', name: 'filter', value: null }))
    dispatch(formUpdateAction({ form: 'reviews', name: 'sort', value: 'dateCreated:desc' }))
    dispatch(updateReviews(data.adobeRecord.numListReviewsPages, data.reviews))
    dispatch({ type: SHARED_LOADING_END })
    dispatch({ type: PDP_SET_DATA_COMPLETE })
  }
}

export const updateReviews = (pages, reviews) => {
  return dispatch => {
    dispatch({ type: SET_REVIEWS_PAGE, payload: 1 })
    dispatch({ type: SET_REVIEWS_PAGES, payload: pages })
    dispatch({ type: REVIEWS_START, payload: reviews })
  }
}

export const resetProductQaFaq = (data) => {
  return dispatch => {
    dispatch({ type: UPDATE_IS_FAQ, payload: data.isFAQ })
    dispatch({ type: CLEAR_QUESTION_ANSWER })
    dispatch({ type: CLEAR_CHECKOUT_COMMENTS })
    dispatch({ type: CLEAR_FAQ })
  }
}

// only used when users go back to the previous product using any carousels or size buttons
export const backProductDetailAction = () => {
  return (dispatch, reduxState) => {
    const store = reduxState()
    dispatch({ type: SHARED_LOADING_START })
    const searchParams = new URLSearchParams(window.location.search)
    let parameter = searchParams.get('otherSize')
    if (!parameter) {
      parameter = store.previousProduct.productPartNumber || store.productDetail.productPartNumber
    }
    dispatch(formUpdateAction({ form: 'backForm', name: 'productPartNumber', parameter }))
    dispatch(formSubmitAction({
      form: 'backForm',
      method: 'get',
      action: `/product.json?id=${parameter}`,
      successAction: updateProductDetailData
    }))
  }
}

export const addFavoritePDPAction = (itemNumber) => {
  return (dispatch) => {
    dispatch({ type: SHARED_LOADING_START })
    axios.get(`${window.location.origin}/myaccount/favorites.html?submit=addToFavorites&partNum=${itemNumber}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: UPDATE_CART_PRODUCT_DETAILS_FAVORITES, payload: response.data.data.hasfavorite.partnum })
          dispatch({ type: UPDATE_PRODUCT_DETAILS_FAVORITES, payload: 'added' })
          dispatch({ type: SHARED_LOADING_END })
        }
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}

export const checkFavorites = (itemNum) => {
  return (dispatch) => {
    axios.get(`${window.location.origin}/favapi/hasfavorite?partnum=${itemNum}`)
      .then((response) => {
        if (response?.data?.data?.hasfavorite?.isfavorited === 'true') {
          dispatch({ type: UPDATE_CART_PRODUCT_DETAILS_FAVORITES, payload: response.data.data.hasfavorite.partnum })
        }
        dispatch({ type: UPDATE_PRODUCT_DETAILS_FAVORITES, payload: response.data.data.hasfavorite.isfavorited })
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}

export const erSelectedUpdate = (productID, ogChecked) => {
  return (dispatch) => {
    dispatch({ type: SHARED_LOADING_START })
    axios.get(`${window.location.origin}/product.json?id=${productID}&og=${ogChecked}`)
      .then((response) => {
        dispatch({ type: UPDATE_PRODUCT_DETAILS, payload: response.data.adobeRecord})
        dispatch({ type: SHARED_LOADING_END })
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}

export const getFAQs = (itemNumber) => {
  return (dispatch) => {
    dispatch({ type: TAB_LOADING, payload: true })
    axios.get(`${window.location.origin}/productpage.json?id=${itemNumber}&tab=FAQ`)
      .then((response) => {
        dispatch({ type: FAQ_START, payload: response.data.faq })
        dispatch({ type: TAB_LOADING, payload: false })
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}

export const getQAAndComments = (itemNumber) => {
  return (dispatch) => {
    dispatch({ type: TAB_LOADING, payload: true })
    axios.get(`${window.location.origin}/productpage.json?id=${itemNumber}&tab=QA`)
      .then((response) => {
        dispatch({ type: QUESTION_ANSWER_START, payload: response.data.qa })
        dispatch({ type: CHECKOUT_COMMENTS_START, payload: response.data.comments })
        dispatch({ type: UPDATE_QUESTIONS_TOTAL, payload: response.data.totalQuestions })
        dispatch({ type: UPDATE_ANSWERS_TOTAL, payload: response.data.totalAnswers })
        dispatch({ type: TAB_LOADING, payload: false })
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}