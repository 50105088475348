import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Heart = ({
  height = `2.7rem`,
  width = `2.7rem`,
  fill,
  stroke = 'none',
  strokeWidth = '0'
}) => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 500 400' width={width} height={height}>
      <title>Favorites Heart</title>
      <g>
        <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} d='M252.2,72.8c28.7-30,48.9-55.9,93.1-60.9C428.5,2.3,505,87.4,463,171.2c-11.9,23.9-36.3,52.2-63.2,80.1
              c-29.5,30.6-62.2,60.5-85.1,83.2l-62.4,61.9l-51.6-49.7C138.6,287,37.4,211.8,34,118.6C31.7,53.3,83.2,11.4,142.5,12.2
              C195.5,12.9,217.8,39.2,252.2,72.8L252.2,72.8L252.2,72.8z' />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  margin-right: 1rem;
`

const Path = styled.path`
  fill: ${props => props.fill ? props.fill : props.theme.primary};
  stroke: ${props => props.stroke};
  stroke-width: ${props => props.strokeWidth};
`
