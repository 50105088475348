import React from 'react'
import styled, { css } from 'styled-components'
import { SMSIcon } from '../_svgs/SMSIcon/SMSIcon'
import { MailIcon } from '../_svgs/MailIcon/MailIcon'
import { GiftIcon } from '../_svgs/GiftIcon/GiftIcon'
import { FacebookIcon } from '../_svgs/FacebookIcon/FacebookIcon'
import { InstagramIcon } from '../_svgs/InstagramIcon/InstagramIcon'
import { PinterestIcon } from '../_svgs/PinterestIcon/PinterestIcon'
import { XIcon } from '../_svgs/XIcon/XIcon'
import { CSSLgMax, CSSLgMin } from '../Responsive/Responsive'
import { utagLink } from '../../tealium'

export const FooterThreeColumn = () => {
  const isIntl = window ? window?.isIntl : false

  const emailHandler = () => {
    if (window) {
      window._klOnsite = window._klOnsite || []
      window._klOnsite.push(['openForm', 'WXaT6m'])
    }
    utagLink({
      'tealium_event': 'email-sms-popup-trigger'
    })
  }

  const smsHandler = () => {
    if (window) {
      window._klOnsite = window._klOnsite || []
      window._klOnsite.push(['openForm', 'Yx8KEP'])
    }
    utagLink({
      'tealium_event': 'email-sms-popup-trigger'
    })
  }



  return (
<>
      <CSSLgMin>
        <FooterGreenBanner>
        <FooterThreeCol28>
          <FooterMainHeader>Contact Us</FooterMainHeader>
          <FooterSocial>
            <FooterSocialIcons><a href='https://www.facebook.com/SwansonHealth' className='cTest_footer_facebook-icon' data-target='facebook' target='_blank' rel='noopener noreferrer'><FacebookIcon width={100} /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://instagram.com/swansonhealth' className='cTest_footer_instagram-icon' data-target='instagram' target='_blank' rel='noopener noreferrer'><InstagramIcon width={45} /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://pinterest.com/swansonhealth/' className='cTest_footer_pinterest-icon' data-target='pinterest' target='_blank' rel='noopener noreferrer'><PinterestIcon width={45} /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://twitter.com/swansonhealth' className='cTest_footer_twitter-icon' data-target='twitter' target='_blank' rel='noopener noreferrer'><XIcon width={45} /></a></FooterSocialIcons>
          </FooterSocial>
        </FooterThreeCol28>
        <FooterThreeCol44>
          {!isIntl &&
            <>
              <FooterMainHeader>Exclusive Emails and Text Offers!</FooterMainHeader>
              <FooterFlex>
                <FooterCol8>
                  <FooterPaddingTop>
                    <MailIcon width={28} height={28} />
                  </FooterPaddingTop>
                </FooterCol8>
                <FooterCol32 className='footer-btn-trck cTest_footer_get-deals klaviyo_form_trigger' data-target='email-trigger' onClick={emailHandler}>
                  <FooterSignUpHtext>Get deals and news</FooterSignUpHtext>
                  <br />
                  <FooterSignUpPtext>straight to your inbox!</FooterSignUpPtext>
                </FooterCol32>
                <FooterCol8>
                  <FooterPaddingTop>
                    <SMSIcon width={28} height={28} />
                  </FooterPaddingTop>
                </FooterCol8>
                <FooterCol42 className='footer-btn-trck cTest_footer_sms klaviyo_form_trigger' data-target='sms-trigger' onClick={smsHandler}>
                  <FooterSignUpHtext>Receive SMS text alerts on our</FooterSignUpHtext>
                    <br />
                  <FooterSignUpPtext>best sales and new products</FooterSignUpPtext>
                </FooterCol42>
              </FooterFlex>
            </>
          }
        </FooterThreeCol44>
        <FooterThreeCol28>
          <FooterMainHeader>Refer A Friend</FooterMainHeader>
          <FooterFlex>
            <FooterCol8>
              <FooterPaddingTop>
                <GiftIcon width={26} height={26} />
              </FooterPaddingTop>
            </FooterCol8>
            <ReferDiv>
              <FooterPaddingTop>
                <ReferA data-target='refer-a-friend' className='cTest_footer_raf' href='https://www.swansonvitamins.com/landing-pages/refer-a-friend.html'>
                  <div>
                    <FooterSignUpHtext>Give friends 20% off their first order</FooterSignUpHtext>
                    <br />
                    <FooterSignUpPtext>Get 20% off when they purchase</FooterSignUpPtext>
                  </div>
                </ReferA>
              </FooterPaddingTop>
            </ReferDiv>
          </FooterFlex>
        </FooterThreeCol28>
        </FooterGreenBanner>
      </CSSLgMin>
      <CSSLgMax>
        <FooterGreenBanner>
          {!isIntl &&
            <FooterCol1002>
              <FooterMainHeader>Exclusive Emails and Text Offers!</FooterMainHeader>
              <FooterFlex>
                <FooterCol8>
                  <MailIcon width={30} height={30}/>
                </FooterCol8>
                <FooterCol92
                  data-testid='emailBtn'
                  className='footer-btn-trck cTest_footer_get-deals klaviyo_form_trigger'
                  data-target='email-trigger'
                  onClick={emailHandler}
                >
                  <FooterSignUpHtext>Get deals and news</FooterSignUpHtext>
                  <br/>
                  <FooterSignUpPtext>straight to your inbox!</FooterSignUpPtext>
                </FooterCol92>
              </FooterFlex>
              <FooterFlex>
                <FooterCol8>
                  <SMSIcon width={30} height={30}/>
                </FooterCol8>
                <FooterCol92
                  data-testid='smsBtn'
                  className='footer-btn-trck cTest_footer_sms klaviyo_form_trigger'
                  data-target='sms-trigger'
                  onClick={smsHandler}
                >
                  <FooterSignUpHtext>Receive SMS text alerts on our</FooterSignUpHtext>
                  <br/>
                  <FooterSignUpPtext>best sales and new products</FooterSignUpPtext>
                </FooterCol92>
              </FooterFlex>
            </FooterCol1002>
          }
        <FooterCol1001>
          <FooterMainHeader>Contact Us</FooterMainHeader>
          <FooterSocial>
            <FooterSocialIcons><a href='https://www.facebook.com/SwansonHealth' className='cTest_footer_facebook-icon' data-target='facebook' target='_blank' rel='noopener noreferrer'><FacebookIcon /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://instagram.com/swansonhealth' className='cTest_footer_instagram-icon' data-target='instagram' target='_blank' rel='noopener noreferrer'><InstagramIcon /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://pinterest.com/swansonhealth/' className='cTest_footer_pinterest-icon' data-target='pinterest' target='_blank' rel='noopener noreferrer'><PinterestIcon /></a></FooterSocialIcons>
            <FooterSocialIcons><a href='https://twitter.com/swansonhealth' className='cTest_footer_twitter-icon' data-target='twitter' target='_blank' rel='noopener noreferrer'><XIcon /></a></FooterSocialIcons>
          </FooterSocial>
        </FooterCol1001>
        <FooterCol1003>
          <FooterMainHeader>Refer A Friend</FooterMainHeader>
          <FooterFlex>
           <FooterCol8>
             <GiftIcon width={28} height={28} />
           </FooterCol8>
           <ReferDiv>
             <ReferA data-target='refer-a-friend' className='cTest_footer_raf' href='https://www.swansonvitamins.com/landing-pages/refer-a-friend.html'><FooterSignUpHtext>Give friends 20% off their first order</FooterSignUpHtext>
              <br />
              <FooterSignUpPtext>Get 20% off when they purchase</FooterSignUpPtext></ReferA>
           </ReferDiv>
         </FooterFlex>
        </FooterCol1003>
        </FooterGreenBanner>
      </CSSLgMax>
 </>
 )
}

const FooterGreenBanner = styled.div`
  background-color: ${props => props.theme.white};
  border-bottom: 2px solid #2F711C;
  border-top: 2px solid #2F711C;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 150px;
  flex-wrap: wrap;
  display: flex;
  padding: 1.5rem!important;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
    flex-flow: row; 
  }
  @media print {
    display: none;
  }
`
const FooterFlex = styled.div`
  display: flex!important;
  justify-content: center;
  align-items: center;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-left: 1rem;
  }
`
const FooterThreeCol28 = styled.div`
  width: 24%;
  justify-content: center;
  ${props => props.styles};
`
const FooterThreeCol44 = styled.div`
  width: 46%;
`
const FooterCol8 = styled.div`
  width: 8%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: .6rem;
`
const ThreeColButton = css`
  border: 0;
  background: transparent;
  text-align: left;
  max-height: 5rem;
  &:hover p{
    text-decoration: underline;
  }
`
const FooterCol42 = styled.button`
  width: 42%;
  font-family: inherit;
  line-height: inherit;
  padding: 0.25rem;
  ${ThreeColButton};
`
const FooterCol32 = styled.button`
  width: 32%;
  font-family: inherit;
  line-height: inherit;
  padding: 0.25rem;
  ${ThreeColButton};
`
const ReferDiv = styled.div`
  width: 89%;
  padding-left: 1%;
  display: flex;
  align-items: center;
  height: 5rem;
  a:focus {
    box-shadow: none;
    outline: 0.1rem auto ${props => props.theme.black};
  }
`
const ReferA = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.black};
    text-decoration: underline;
  }
`
const FooterCol92 = styled.button`
  ${ThreeColButton};
  width: 88%;
  padding-left: 4%;
  height: 5rem;
  line-height: 1.6
`
const FooterCol1001 = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    order: 1;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: 865px) {
    width: 25%;
  }
`
const FooterCol1002 = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    order: 2;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: 865px) {
    width: 40%;
  }
`
const FooterCol1003 = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    order: 3;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: 865px) {
    width: 35%;
  }
  ${props => props.styles};
`
const FooterMainHeader = styled.p`
  font-size: 1.7rem;
  font-weight: normal;
  text-align: center;
`
const FooterPaddingTop = styled.div`
`

const FooterSocial = styled.div`
  justify-content: center;
  display: flex;
  margin: 0 1rem;
`
const FooterSocialIcons = styled.div`
  width: 5rem;
  height: 5rem;
  margin: 0 0 2rem 2rem;
  a:focus{
    box-shadow: none;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 3.5rem;
    margin: 0 0 0.5rem 0.5rem;
  }
`
const FooterSignUpHtext = styled.span`
  color: #2F711C;
  font-size: 1.52rem;
  cursor: pointer;
  margin: 0;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: 1336px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.4rem;
 }
`
const FooterSignUpPtext = styled.span`
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0;
  color: ${props => props.theme.black};
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: 1199px) {
    font-size: 1.3rem
  }
`
