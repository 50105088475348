import { FORM_UPDATE, FORM_INIT, FORM_CLEAR } from '../constants/formConstants'
import axios from 'axios'
import { hideErrorsAction, showErrorsAction } from './errorActions'
import { APP_ERROR, APP_SET_DATA_COMPLETE, LOADING_COMPLETE } from '../constants/appConstants'
import { createLogger, format, transports } from 'winston'

export const formUpdateAction = (update) => (
  { type: FORM_UPDATE, update }
)

export const formClearAction = (formData) => {
  return function (dispatch, reduxState) {
    dispatch({ type: FORM_CLEAR, payload: formData })
  }
}

export const formSubmitAction = (formData) => {
  return function (dispatch, reduxState) {
    // default is type body
    if (!formData.dataType) {
      formData.dataType = 'body'
    }
    let formString = ''
    // add extra data to form submit if necessary (must be added to form data on form components to use.)
    if (formData.dataType === 'body') {
      if (formData.extraData) {
        for (let key in formData.extraData) {
          if (formData.extraData.hasOwnProperty(key)) {
            formString = `${formString}&${key}=${formData.extraData[key]}`
          }
        }
      }
      // assembling a string to send on back of url.
      for (let key in reduxState().forms[formData.form]) {
        if (reduxState().forms[formData.form].hasOwnProperty(key)) {
          if (formData.encodeURIComponents) {
            formString = `${formString}&${key}=${encodeURIComponent(reduxState().forms[formData.form][key])}`
          } else {
            formString = `${formString}&${key}=${reduxState().forms[formData.form][key]}`
          }
        }
      }
    }

    axios.defaults.headers.common['Accept'] = 'application/json'
    let url = ''
    if (formData.action) {
      url = formData.action
    } else {
      url = reduxState().checkout.flowURL
    }

    axios({
      url: url,
      data: formData.dataType === 'json' ? reduxState().forms[formData.form] : `${formString}`,
      params: formData.dataType === 'param' ? reduxState().forms[formData.form] : null,
      method: formData.method
        ? (formData.method === 'get'
          ? 'get'
          : 'post'
        )
        : 'post'
    }).then((response) => {
      dispatch(hideErrorsAction())
      if (!response.data.messages) { // check for messages (errors)
        // success actions
        if (formData.successAction) {
          dispatch(formData.successAction(response.data, formData))
        }
        // fire analytic needs as a result of a success form submit
        if (!formData.noAnalytics) {
          dispatch({ type: APP_SET_DATA_COMPLETE })
        }
      } else {
        dispatch(showErrorsAction(response.data.messages))
        if (formData.errorAction) {
          dispatch(formData.errorAction)
        }
      }
    }).catch((error) => {
      const serviceName = window.location.hostname.indexOf('www.') > -1 ? 'prod-ws' : (window.location.hostname.indexOf('pre-prod.') > -1 ? 'pre-prod-ws' : (window.location.hostname.split('.swansonvitamins')[0]))

      const httpTransportOptions = {
        host: 'http-intake.logs.datadoghq.com',
        path: `/api/v2/logs?dd-api-key=pubac3655a4ce6335ef89e54e67e136f8f4&ddsource=nodejs&service=${serviceName}`,
        ssl: true
      }

      const logger = createLogger({
        level: 'error',
        exitOnError: false,
        format: format.json(),
        transports: [
          new transports.Http(httpTransportOptions),
        ],
      })

      logger.error(`React form error occurred in: ${formData.form}`,
        {
          errorInfo: error,
          formData: formData,
          url: window.location.href
        }
      )
      if (formData.errorAction) {
        dispatch(formData.errorAction)
      }
      dispatch({ type: LOADING_COMPLETE })
      dispatch({ type: APP_ERROR, payload: {} })
    })
  }
}
export const formInitAction = (formName, defaults) => (
  {
    type: FORM_INIT, payload: { formName: formName, defaults: defaults }
  }
)
