import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { StandardLink } from '../../_styled/Link'
import { Field } from '../../_inputs/Field/Field'
import { loginSuccessAction } from '../../../actions/sharedActions'
import { OptionButton } from '../../_inputs/OptionButton/OptionButton'
import { useSelector, useDispatch } from 'react-redux'
import { CTAButton } from '../../_inputs/Button/Button'
import {
  CONTINUED_PROBLEM_ERROR,
  INVALID_EMAIL_PASS_ERROR,
  RESET_EMAIL_PASS_ERROR,
  RESET_CONTINUED_PROB_ERROR
} from '../../../constants/loginConstants'
import { hideErrorsAction } from '../../../actions/errorActions'

export const LoginForm = (props) => {
  const dispatch = useDispatch()
  const [ disableSubmit, setDisable ] = useState(true)
  const invalidState = useSelector(state => state?.login?.invalidEmailPassError)
  const continuedErrorState = useSelector(state => state?.login?.continuedProblemError)

  const formData = {
    form: 'login',
    encodeURIComponents: true,
    action: '/j_spring_security_check',
    successAction: loginSuccessAction,
  }

  useEffect(() => {
    if (props.formState?.login) {
      let validEmail
      if (props.formState.login?.j_username) {
        validEmail = checkValidEmail(props.formState.login?.j_username)
      }

      if (validEmail && props.formState.login?.j_password) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    }
  }, [props.formState])

  useEffect(() => {
    return () => {
      props.clearForm(formData)
      dispatch({ type: RESET_EMAIL_PASS_ERROR })
      dispatch({ type: RESET_CONTINUED_PROB_ERROR })
      dispatch(hideErrorsAction())
    }
  }, [])

  useEffect(() => {
    if (props.errorStates && props.errorStates.hasOwnProperty('form')) {
      if (props.errorStates.form[0] === 'Invalid username or password') {
        if (invalidState) {
          dispatch({ type: CONTINUED_PROBLEM_ERROR })
        } else {
          dispatch({ type: INVALID_EMAIL_PASS_ERROR })
        }
      }
    }
  }, [props.errorStates])

  const checkValidEmail = (email) => {
    let emailCheck = email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return !!emailCheck
  }

  const getState = fieldObj => {
    fieldObj.form = formData.form
    props.updateForm(fieldObj)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    props.updateForm({ form: formData.form, name: '_eventId', value: 'backToLoggedIn' })
    props.submitForm(formData)
  }

  return (
    <>
      <LoginHeaderH1>Sign In to Your Account</LoginHeaderH1>
      {props?.showAccountCreation &&
        <LoginSubHeaderDiv>
          Don’t have an account?
          <StandardLink
            href='/gateway-register'
            title='Create an account'
            styles={css`
                  font-weight: 600;
                  margin-left: 0.5rem;
                  text-decoration: underline !important;
                  &:focus {
                    border: none;
                    outline: none;
                    display: inline;
                  }
                `}
          >Create an account</StandardLink>
        </LoginSubHeaderDiv>
      }
      <Form
        onSubmit={(e) => {
          submitHandler(e)
        }}
      >
        {invalidState &&
          <EmailAndPasswordDisclaimerContainerDiv aria-live='assertive' role='alert'>
            <ExclamationContainerDiv>
              <EmailAndPasswordExclamationDiv>!</EmailAndPasswordExclamationDiv>
            </ExclamationContainerDiv>
            <EmailAndPasswordDisclaimer>
              <div>Please provide a valid email and password</div>
            </EmailAndPasswordDisclaimer>
          </EmailAndPasswordDisclaimerContainerDiv>
        }
        {invalidState && continuedErrorState &&
          <ContinuedErrorContainer aria-live='assertive' role='alert'>
            <ContinuedErrorHeader>Continuing to get this error?</ContinuedErrorHeader>
            <ContinuedErrorBody>
              Click
              <StandardLink
                href='/ForgotPassword'
                styles={css`
                  font-weight: 600;
                  color: ${props => props.theme.primary};
                  line-height: 21px;
                  font-size: 1.6rem;
                  margin-left: 0.4rem;
                  text-decoration: underline !important;
                  &:focus {
                    border: none;
                    outline: none;
                    display: inline;
                  }
                `}
                title='Forgot password'
              >here</StandardLink> to retrieve your account information or call us at 1-800-824-4491
            </ContinuedErrorBody>
          </ContinuedErrorContainer>
        }
        <Field
          focused='true'
          hideError
          labelText='Email'
          name='j_username'
          styles={FieldStyles}
          id='username'
          autocomplete='email'
          returnState={getState}
          showLabel
          addRef={props.addToRefsArray}
          value={props.formState?.login?.j_username || ''}
          fieldStyles={css`
            margin-top: 2.4rem !important;
            border-color: ${props => invalidState || continuedErrorState ? props.theme.darkRed : props.theme.primary};
            border-width: 1px;
            outline: none;
            :focus-visible {
              border: 1px solid ${props => invalidState || continuedErrorState ? props.theme.darkRed : props.theme.primary};
            }
          `}
          focusStyle={css`
            background-color: ${props => props.theme.white};
            border-color: ${props => props.theme.primary};
          `}
          useLabelPlaceholder
        />
          <Field
            labelText='Password'
            hideError
            autocomplete='current-password'
            inputType='password'
            styles={FieldStyles}
            name='j_password'
            id='currentPassword'
            returnState={getState}
            showLabel
            addRef={props.addToRefsArray}
            value={props.formState?.login?.j_password || ''}
            fieldStyles={css`
              margin-top: 2.4rem !important;
              border-color: ${props => invalidState || continuedErrorState ? props.theme.darkRed : props.theme.primary};
              border-width: 1px;
              outline: none;
              :focus-visible {
                border: 1px solid ${props => invalidState || continuedErrorState ? props.theme.darkRed : props.theme.primary};
              }
            `}
            focusStyle={css`
              background-color: ${props => props.theme.white};
              border-color: ${props => props.theme.primary};
            `}
            useLabelPlaceholder
          />
        <OptionButton
          useBox={false}
          labelText='Please keep me signed in*'
          name='_spring_security_remember_me'
          value='true'
          defaultChecked='false'
          returnState={getState}
          styles={css`
            margin-top: 1.6rem;              
          `}
          inputStyles={css`
            width: 2.5rem !important;
            height: 2.5rem !important;
            border-radius: 0.8rem !important;
            border: 1px solid ${props => props.theme.primary} !important;
          `}
        />
        <LoginCTAButton
          disabled={disableSubmit}
          styles={css`
            cursor: ${disableSubmit ? 'not-allowed' : 'pointer'};
            background: ${props => disableSubmit ? '#b93e09;' : props.theme.darkestOrange};
            opacity: ${disableSubmit ? '0.4;' : '1'};
          `}
        >
          Sign In
        </LoginCTAButton>
        <StandardLink
          href='/ForgotPassword'
          styles={css`
            font-weight: 600;
            line-height: 21px;
            font-size: 1.8rem;
            margin-top: 2.4rem;
            text-align: center;
            text-decoration: underline !important;
            &:focus {
              border: none;
              outline: none;
              display: inline;
            }
          `}
          title='Forgot password'
        >Forgot your password?</StandardLink>
        <CheckBoxDisclaimerDiv>*By checking this box you won't have to sign in as often on this device. For your security, we recommend only checking this box on your personal devices.</CheckBoxDisclaimerDiv>
      </Form>
    </>
  )
}

const LoginHeaderH1 = styled.h1`
  margin-top: 3.6rem;
  line-height: 30px;
  text-align: center;
  font-size: 2.8rem;
  font-weight: 600;
  color: ${props => props.theme.black};
  @media (max-width: 424px) {
    font-size: 2.5rem;
  }
`

const LoginSubHeaderDiv = styled.div`
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 14px;
  text-align: center;
  @media (max-width: 424px) {
    font-size: 1.6rem;
  }
`

const FieldStyles = css`
  margin-bottom: .6rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const CheckBoxDisclaimerDiv = styled.div`
  font-size: 1.6rem;
  line-height: 21px;
  margin-top: 2.4rem;
  text-align: left;
`

const LoginCTAButton = styled(CTAButton)`
  margin-top: 3.2rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  line-height: 22.4px;
  font-weight: 500;
  ${props => props.styles};
`

const EmailAndPasswordDisclaimerContainerDiv = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  border-radius: 0.8rem;
  border: 2px solid ${props => props.theme.darkRed};
  margin-top: 4rem;
  height: 7.8rem;
`

const EmailAndPasswordExclamationDiv = styled.div`
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  color: ${props => props.theme.white};
  border-radius: 40px;
  border: 1px solid ${props => props.theme.white};
  width: 2.1rem;
  height: 2.1rem;
`

const ExclamationContainerDiv = styled.div`
  background: ${props => props.theme.darkRed};
  border-radius: 5px 0px 0px 5px;
  display: flex;
  width: 3.7rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 21px;
`

const EmailAndPasswordDisclaimer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.darkRed};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 23px;
  width: 100%;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`

const ContinuedErrorContainer = styled.div`
  margin-top: 1.6rem;
  text-align: left;
`

const ContinuedErrorHeader = styled.div`
  color: ${props => props.theme.black};
  font-weight: 600;
  line-height: 21px;
  font-size: 1.6rem;
`

const ContinuedErrorBody = styled.div`
  font-size: 1.6rem;
  line-height: 21px;
  font-weight: 400;
  color: ${props => props.theme.black};
`
