import React from 'react'
import styled, { css } from 'styled-components'

export const ProductFlag = ({
  newWebItem,
  bogo = false,
  outletmall = false,
  newImproved = false,
  rewards2x,
  rewards3x,
  rewards4x,
  onMYACFav = false,
  newLowerPrice
}) => {
  let flagText = ''
  let width = ''
  let flagColor = ''
  if (newWebItem) {
    flagText = 'NEW'
  } else if (bogo) {
    flagText = 'BOGO'
  } else if (outletmall) {
    flagText = 'Clearance'
  } else if (newImproved) {
    flagText = 'New & Improved'
  } else if (rewards2x) {
    flagText = '2X Rewards Points'
  } else if (rewards3x) {
    flagText = '3X Rewards Points'
  } else if (rewards4x) {
    flagText = '4X Rewards Points'
  } else if (newLowerPrice) {
    flagText = 'NEW Lower Price'
  } else {
    return null
  }

  if (newImproved) {
    width = '12rem'
  } else if (newLowerPrice || rewards2x || rewards3x || rewards4x) {
    width = '14rem'
  } else {
    width = '10rem'
  }

  if (rewards2x || rewards3x || rewards4x) {
    flagColor = css`${props => props.theme.turtleGreen}`
  } else if (newLowerPrice) {
    flagColor = css`${props => props.theme.darkestOrange}`
  } else {
    flagColor = css`${props => props.theme.tuskGreen}`
  }

  return (
    <ProductFlagDiv
      styles={css`
        background-color: ${flagColor};
        top: ${onMYACFav ? '2rem' : '0rem'};
      `}
      width={width}>
      <ProductFlagFlipSpan
        styles={css`
          color: ${props => (rewards2x || rewards3x || rewards4x || newLowerPrice) ? props.theme.white : props.theme.black} !important;
        `}
        width={width}
      >
        {flagText}
      </ProductFlagFlipSpan>
    </ProductFlagDiv>
  )
}

const ProductFlagDiv = styled.div`
  width: 2.4rem;
  position: absolute;
  height: ${props => props.width};
  text-align: center;
  right: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  ${props => props.styles};
`

const ProductFlagFlipSpan = styled.span`
  transform: rotate(90deg);
  transform-origin: left top 0;
  left: 2.3rem;
  width: ${props => props.width};
  position: absolute;
  font-weight: 400;
  ${props => props.styles};
`
