import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Cookies from 'js-cookie'
import { LightBox } from '../LightBox/LightBox'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { LightBoxCentered } from '../_styled/LightBoxOptions'
import { LinkThatLooksLikeAButton, StandardLink } from '../_styled/Link'
import { TransparentButton } from '../_inputs/Button/Button'
import { FreeShippingProgressBar } from '../FreeShippingProgressBar/FreeShippingProgressBar'
import { PDPCarouselContainer } from '../PDPCarouselContainer/PDPCarouselContainer'
import { ProductFlag } from '../ProductFlag/ProductFlag'

export const ATCModal = ({
  product,
  quantity = '1',
  updateCart
}) => {
  const dispatch = useDispatch()
  const cartData = useSelector(state => state?.cart?.cartData)
  const shoppingBagTotal = useSelector(state => state?.cart?.shoppingBag?.totalDiscountPrice)
  const qntCorrection = parseInt(quantity) < 1 ? '1' : quantity
  const orderSubtotal = cartData ? cartData.orderSubTotal : (shoppingBagTotal ? shoppingBagTotal.toString() : '0')
  const noDollarTotal = orderSubtotal.indexOf('$') > -1 ? orderSubtotal.split('$')[1] : orderSubtotal

  const closeHandler = () => {
    if (document) {
      document.body.style.overflow = 'auto'
    }
    dispatch({type: SHARED_CLEAR_INSTANCE})
  }

  return (
    <LightBox
      closeHandler={closeHandler}
      show
      styles={css`
        ${LightBoxCentered};
        display: flex;
        flex-wrap: wrap;
        padding: 1.6rem;
        border-radius: 0.8rem;
        @media (min-width: 807px) {
          width: 80.7rem !important;
        }
        @media (max-width: 806px) {
          width: 95% !important;
        }
      `}
    >
      <H2 styles={(Cookies.get('ShopSwansonInternational')) ? css`margin-bottom: 0.8rem;` : css`margin-bottom: 2.4rem;`}>Item Added to Cart</H2>
      {(Cookies.get('ShopSwansonInternational')) &&
        <InternationalTextMsg>
          Note: We are unable to ship to international addresses. Shop your favorite Swanson® products at iHerb.com.
          <StandardLink
            href='https://www.iherb.com/c/swanson?mbsrc=SWV'
            title='Shop iHerb.com'
            styles={css`
              text-decoration: underline;
              margin-left: 0.5rem;  
            `}
          >
            Shop iHerb
          </StandardLink>
        </InternationalTextMsg>
      }
      <ATCModalTopContainerDiv>
        <ATCModalBorderContainerDiv
          styles={css`
            padding: 1.6rem 0.9rem; 
            width: 462px;
            @media(max-width: 806px) {
              width: 100%;
            }
          `}
        >
          <ATCModalImgContainer>
            <ATCModalImg
              src={`https://media.swansonvitamins.com/images/items/250/${product.productPartNumber}.jpg`}
              alt={`${product.productVendor} ${product.productName}`}
            />
            <ProductFlag
              newWebItem={product.newWebItem}
              newImproved={!!product.flagMap?.new_improved}
              bogo={product.bogo}
              outletmall={product.outletmall}
              rewards2x={product?.rewards2x || product?.flags?.indexOf('reward2x') > -1 || product?.flagMap?.reward2x}
              rewards3x={product?.rewards3x || product?.flags?.indexOf('reward3x') > -1 || product?.flagMap?.reward3x}
              rewards4x={product?.rewards4x || product?.flags?.indexOf('reward4x') > -1 || product?.flagMap?.reward4x}
              newLowerPrice={(product?.flags?.indexOf('new_lower_price') > -1) || (product?.flagMap?.new_lower_price)}
            />
          </ATCModalImgContainer>
          <ATCModalProductInfoDiv>
            <ATCModalProductBrandDiv>{product.productVendor}</ATCModalProductBrandDiv>
            <ATCModalProductNameDiv>{product.productName}</ATCModalProductNameDiv>
            <ATCModalProductDetailsDiv>{product.productPartNumber} - {product.productDetails}</ATCModalProductDetailsDiv>
            <ATCModalQuantityText>Quantity: {qntCorrection}</ATCModalQuantityText>
            {(product.tooLowToShow || product.percentDiscount <= 0 || product.bogo) ?
              <ATCModalTLTSText>
                {product.bogo ? qntCorrection * 2 : qntCorrection}&nbsp;{product.bogo ? '@' : 'for'}&nbsp;${(((parseFloat(product.tooLowToShow ? product.productDiscountPrice : product.everyDayLowPrice)*100)*qntCorrection) / 100).toFixed(2)}
              </ATCModalTLTSText>
              :
              <>
                <ATCModalPercentTxtDiv>SAVE {Math.floor(product.percentDiscount * 100)}%</ATCModalPercentTxtDiv>
                <ATCModalTotalPriceDiv>
                  <ATCModalPricesContainer>
                    <ATCModalDiscPriceDiv styles={css`margin-bottom: 1.6rem;`}>${(((parseFloat(product.productDiscountPrice)*100)*parseFloat(qntCorrection)) / 100).toFixed(2)}</ATCModalDiscPriceDiv>
                    {qntCorrection > 1 &&
                      <ATCModalDiscPriceDiv styles={css`font-size: 1.6rem;`}>${product.productDiscountPrice}&nbsp;ea.</ATCModalDiscPriceDiv>
                    }
                  </ATCModalPricesContainer>
                  <ATCModalPricesContainer styles={css`margin-left: 2.4rem; @media(max-width: 360px) { margin-left: 0.8rem; }`}>
                    <ATCModalNormalPriceDiv styles={css`margin-bottom: 1.6rem;`}>${(((parseFloat(product.everyDayLowPrice)*100)*parseFloat(qntCorrection)) / 100).toFixed(2)}</ATCModalNormalPriceDiv>
                    {qntCorrection > 1 &&
                      <ATCModalNormalPriceDiv styles={css`font-size: 1.4rem;`}>${product.everyDayLowPrice}&nbsp;ea.</ATCModalNormalPriceDiv>
                    }
                  </ATCModalPricesContainer>
                </ATCModalTotalPriceDiv>
              </>
            }
          </ATCModalProductInfoDiv>
        </ATCModalBorderContainerDiv>
        <ATCModalBorderContainerDiv
          styles={css`
            display: block;
            width: 285px;
            @media(min-width: 807px) {
              align-items: flex-start;
            }
            @media(max-width: 806px) {
              width: 100%;
              justify-content: center;
            }
          `}
        >
          <ATCModalCartSubText>Cart Subtotal: {orderSubtotal} ({cartData && cartData.numCartItems} Items)</ATCModalCartSubText>
          <ATCModalBtnContainer>
            <ATCModalViewCartA href='/cart' title='View Cart and Checkout'>View Cart & Checkout</ATCModalViewCartA>
          </ATCModalBtnContainer>
          <ATCModalBtnContainer>
            <ATCModalContinueBtn onClick={closeHandler} aria-label='Continue Shopping'>Continue Shopping</ATCModalContinueBtn>
          </ATCModalBtnContainer>
        </ATCModalBorderContainerDiv>
      </ATCModalTopContainerDiv>
      {window?.promoInfo?.sourceCode === 'INT999' &&
        <ATCModalBorderContainerDiv>
          <FreeShippingProgressBar subtotal={noDollarTotal} threshold={29}/>
        </ATCModalBorderContainerDiv>
      }
      <PDPCarouselContainer
        endpoint={`/recs2/cartmodal.json?id=${product.productPartNumber}`}
        carouselType='react-atc-carousel'
        updateCart={updateCart}
        gqlType='cartmodal'
        useGraphQL
        showHeader
        maxProducts={12}
        productID={product.productPartNumber}
        atcProdId={product.productPartNumber}
        carouselContainerStyles={css`justify-content: center;`}
      />
    </LightBox>
  )
}

const H2 = styled.h2`
  display: flex;
  width: 100%;
  font-weight: 600;
  font-size: 3.4rem;
  ${props => props.styles};
`

const ATCModalBorderContainerDiv = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${props => props.theme.gray85};
  border-radius: 0.8rem;
  padding: 1.6rem 1.6rem;
  margin-bottom: 1.6rem;
  ${props => props.styles};
`

const ATCModalProductInfoDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 26.7rem;
  margin-left: 1.6rem;
`

const ATCModalProductBrandDiv = styled.div`
  display: flex;
  width: 100%;
  color: ${props => props.theme.treeGreen};
  font-size: 1.8rem;
`

const ATCModalProductNameDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
`

const ATCModalProductDetailsDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
`

const ATCModalCartSubText = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  @media (max-width: 806px) {
    justify-content: center;
  }
`

const ATCModalQuantityText = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
`

const ATCModalImg = styled.img`
  width: 150px;
  height: 150px;
`

const ATCModalImgContainer = styled.div`
  position: relative;
`

const ATCModalViewCartA = styled(LinkThatLooksLikeAButton)`
  width: 100%;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.darkestOrange};
  max-width: 294px;
  margin-bottom: 0.8rem;
  &:hover {
    border: none;
    color: ${props => props.theme.white};
  }
`

const ATCModalContinueBtn = styled(TransparentButton)`
  width: 100%;
  color: ${props => props.theme.black};
  border-radius: 0.8rem;
  border: 1px solid ${props => props.theme.gray85};
  max-width: 294px;
`

const InternationalTextMsg = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.8rem;
`

const ATCModalTLTSText = styled.div`
  display: flex;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
`

const ATCModalTopContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

const ATCModalPercentTxtDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${props => props.theme.darkestOrange};
  text-decoration: none;
  margin-bottom: 0.8rem;
`

const ATCModalTotalPriceDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props => props.styles};
`

const ATCModalDiscPriceDiv = styled.div`
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  color: ${props => props.theme.darkestOrange}; 
  ${props => props.styles};
`

const ATCModalNormalPriceDiv = styled.div`
  display: flex;
  font-size: 1.8rem;
  color: ${props => props.theme.black};
  text-decoration: line-through;
  width: 100%;
  ${props => props.styles};
`

const ATCModalPricesContainer = styled.div`
  ${props => props.styles};
`

const ATCModalBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`