import React from 'react'
import styled, { css } from 'styled-components'
import { SelectBox } from '../SelectBox/SelectBox'
import { Field } from '../Field/Field'
import { useUniqueId } from '../../../hooks/useUniqueId'

export const QuantitySelectBox = ({
  maxQty,
  name,
  value,
  inputType,
  showLabel,
  id,
  styles,
  returnState,
  addRef,
  includeZero,
  qtyClasses,
  qtyStyles,
  labelStyles = '',
  selectBoxStyles = '',
  // bogoProd = false
}) => {
  let options = []

  if (includeZero) {
    options.push({ text: 0, value: 0 })
  }

  const idGenerator = useUniqueId('uniqueId')

  if (inputType !== 'text') {
    if (maxQty < 6 && includeZero) {
      for (let i = 1; i < maxQty; i++) {
        options.push({ text: i + 1, value: i + 1 })
      }
    } else if (maxQty < 6 && !includeZero) {
      for (let i = 0; i < maxQty; i++) {
        options.push({ text: i + 1, value: i + 1 })
      }
    } else {
      options.push({ text: 1, value: 1 }, { text: 2, value: 2 }, { text: 3, value: 3 }, { text: 4, value: 4 }, { text: 5, value: 5 }, { text: '6+', value: 'more' })
    }
  }

  const getState = data => {
    returnState(data)
  }
  return (
    <Container>
      {inputType !== 'text' &&
        <SelectBox id={id || idGenerator}
          labelText={qtyStyles ? 'Quantity' : 'quantity'}
          name={name}
          value={value}
          styles={styles}
          setState={value}
          options={options}
          returnState={getState}
          showLabel={showLabel}
          qtyClasses={qtyClasses}
          addRef={addRef}
          className='Option__color'
          hiddenOption={parseInt(value) >= 6}
          qtyStyles={qtyStyles}
          selectBoxStyles={selectBoxStyles}
        />
      }
      {inputType === 'text' &&
        <Field autoFocus
          returnState={getState}
          name={name}
          labelText='Quantity'
          id={id || idGenerator}
          inputType='number'
          value={value}
          showLabel={showLabel}
          addRef={addRef}
          focusStyle={css`
            background-color: #fff;
          `}
          labelStyles={labelStyles}
          styles={css`
            display: flex;
            flex-direction: column;
            margin-bottom: 1.6rem;
            max-width: 5.6rem;
          `}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  margin-right: 1rem;
`
