import {
  CHECKOUT_CHANGE_PAYMENT_ACCORDION,
  CHECKOUT_CHOOSE_SHIPPING,
  CHECKOUT_NEXT_STEP,
  CHECKOUT_PAYMENT_RECEIVED,
  CHECKOUT_REFRESH_CC_INFO,
  CHECKOUT_SR_LOGGED_IN,
  GET_PAYMENT_ERRORS,
  CHECKOUT_APP_LOADING,
  CHECKOUT_UPDATE_SESSION_ID
} from '../../constants/checkoutConstants'
import {
  ORDER_APPLY_COUPON,
  ORDER_APPLY_PROMO,
  ORDER_BACKORDERED,
  ORDER_CART,
  ORDER_IS_CALIFORNIA,
  ORDER_IS_EU, ORDER_IS_PROP65,
  ORDER_PROMO_TEXT,
  ORDER_SHIPPING_SELECTED,
  ORDER_UPDATE_TOTALS
} from '../../constants/orderConstants'
import { updateViewAction } from './accordionActions'
import { USER_DATA_AUTHENTICATED, USER_DATA_HAS_PAYMENT_WALLET, USER_DATA_DISTRIBUTOR } from '../../constants/userDataConstants'
import { APP_SET_DATA_COMPLETE } from '../../constants/appConstants'
import { getEasyRefillValid } from './easyRefillActions'
import { resetSessionTimerAction } from './sessionActions'
import { GET_PROMOTION_MESSAGE } from '../../constants/promotionMessagesConstants'
import {formInitAction, formUpdateAction} from '../formActions'
import { ADDRESS_SELECT_CHANGE } from '../../constants/addressConstants'
import {
  CLEAR_CURRENTLY_USED_REWARD,
  UPDATE_CURRENTLY_USED_REWARD,
  UPDATE_SHOW_FREE_SHIP
} from '../../reducers/checkoutReducers/rewardsReducer'

export const checkoutStartAction = (newState) => {
  return (dispatch) => {
    dispatch(resetSessionTimerAction(true))
    dispatch(updateViewAction(newState))
    dispatch({ type: CHECKOUT_NEXT_STEP, payload: newState })
    dispatch({ type: USER_DATA_AUTHENTICATED, payload: newState.authenticated })
    dispatch({ type: USER_DATA_DISTRIBUTOR, payload: newState.distributor })
    dispatch(applyPromoToOrderAction(newState))
    dispatch({ type: ORDER_APPLY_COUPON, payload: newState.orderInfo.cart.cartGiftCertificates })
    dispatch({ type: USER_DATA_HAS_PAYMENT_WALLET, payload: newState.orderInfo.usePaymentWallet })
    dispatch({ type: ORDER_BACKORDERED, payload: newState.orderInfo.cart.hasBackOrdered })
    dispatch({ type: ORDER_IS_CALIFORNIA, payload: newState.hasNonSwansonItem })
    dispatch({ type: ORDER_IS_PROP65, payload: newState.orderInfo.cart.prop65 })
    dispatch({ type: ORDER_IS_EU, payload: sessionStorage.getItem('isEU') || newState.eu })
    dispatch({ type: GET_PAYMENT_ERRORS, payload: newState.paymentErrors })
    dispatch(getEasyRefillValid(newState))
    dispatch({ type: ORDER_SHIPPING_SELECTED, payload: newState.orderInfo.shipping })
    dispatch({ type: CHECKOUT_SR_LOGGED_IN, payload: newState.srloggedIn })
    if (newState?.orderInfo?.shippingAddress) {
      if (newState.orderInfo.shippingAddress.country !== 'USA') {
        dispatch({type: UPDATE_SHOW_FREE_SHIP, payload: false})
      }
    }
    switch (newState.stateId) {
      case 'startAnon':
        break
      case 'getShippingMethod': {
        dispatch({ type: CHECKOUT_CHOOSE_SHIPPING, payload: newState.shippingList })

        break
      }
      case 'getPaymentMethod': {
        dispatch({ type: CHECKOUT_REFRESH_CC_INFO, payload: newState.orderInfo })
        dispatch({ type: ORDER_UPDATE_TOTALS, payload: newState })
        dispatch({ type: CHECKOUT_PAYMENT_RECEIVED, payload: newState.orderInfo.paymentType })
        dispatch(formInitAction('shipping', newState.orderInfo.shippingAddress))
        dispatch(formInitAction('billing', newState.orderInfo.billingAddress))
        break
      }
      case 'placeOrder': {
        dispatch({ type: CHECKOUT_PAYMENT_RECEIVED, payload: newState.orderInfo.paymentType })
        dispatch({ type: ORDER_SHIPPING_SELECTED, payload: newState.orderInfo.shipping })
        dispatch(formInitAction('shipping', newState.orderInfo.shippingAddress))
        dispatch(formInitAction('billing', newState.orderInfo.billingAddress))
        break
      }
      default: {

      }
    }
    dispatch({ type: APP_SET_DATA_COMPLETE })
  }
}

export const redirectSuccessAction = (data) => {
  window.location = data.redirectURL
  toggleLoadingSpinnerAction(false)
}

export const changePaymentAccordionAction = data => {
  return dispatch => {
    dispatch({ type: CHECKOUT_CHANGE_PAYMENT_ACCORDION, payload: data })
  }
}

export const applyPromoToOrderAction = data => {
  return dispatch => {
    dispatch(checkoutAlwaysRunAction(data))
    dispatch({
      type: ORDER_APPLY_PROMO,
      payload: data.promoCode
    })
    dispatch({
      type: ORDER_PROMO_TEXT,
      payload: data.promoShortDescription
    })
    dispatch({
      type: ORDER_CART,
      payload: data.orderInfo.cart.sortedCartItems
    })
    dispatch({
      type: ORDER_UPDATE_TOTALS,
      payload: data
    })
    window._shoprunner_com.cart.srSubTotal = data.orderInfo.cart.totalRoundedCost
    window._shoprunner_com.isMOVSatisfied = data.orderInfo.cart.totalRoundedCost >= 50
    // fire analytic needs as a result of a success form submit
  }
}

export const changeEUStatus = payload => {
  return dispatch => {
    // dispatch(checkoutAlwaysRunAction(data))
    dispatch({ type: ORDER_IS_EU, payload: payload })
  }
}

export const couponSuccessAction = (data, callback) => {
  return dispatch => {
    dispatch(checkoutAlwaysRunAction(data))
    dispatch({ type: ORDER_APPLY_COUPON, payload: data.orderInfo.cart.cartGiftCertificates })
    dispatch({ type: ORDER_UPDATE_TOTALS, payload: data })
    callback()
  }
}

export const refreshDataSuccessAction = (data) => {
  return dispatch => {
    dispatch({ type: ORDER_SHIPPING_SELECTED, payload: data.orderInfo.shipping })
    dispatch({ type: CHECKOUT_SR_LOGGED_IN, payload: data.srloggedIn })
    dispatch({ type: CHECKOUT_CHOOSE_SHIPPING, payload: data.shippingList })
  }
}

export const checkoutAlwaysRunAction = (data, tempFieldObj) => {
  return dispatch => {
    dispatch(resetSessionTimerAction())
    dispatch(updateViewAction(data))
    dispatch({ type: CHECKOUT_NEXT_STEP, payload: data })
    if (data.orderInfo && data.orderInfo.redeemedRewards) {
      if (data.orderInfo.redeemedRewards.length > 0) {
        dispatch({ type: UPDATE_CURRENTLY_USED_REWARD, payload: data.orderInfo.redeemedRewards })
      } else {
        dispatch({ type: CLEAR_CURRENTLY_USED_REWARD })
      }
    }
    if (data.orderInfo && data.orderInfo.paymentType.ccName) {
      dispatch({ type: CHECKOUT_PAYMENT_RECEIVED, payload: data.orderInfo.paymentType })
    }
    if (data.orderInfo) {
      dispatch({ type: ORDER_SHIPPING_SELECTED, payload: data.orderInfo.shipping })
      dispatch({ type: USER_DATA_HAS_PAYMENT_WALLET, payload: data.orderInfo.usePaymentWallet })
      dispatch({ type: ORDER_BACKORDERED, payload: data.orderInfo.cart.hasBackOrdered })
      dispatch({ type: ORDER_IS_CALIFORNIA, payload: data.hasNonSwansonItem })
      dispatch({ type: ORDER_IS_EU, payload: sessionStorage.getItem('isEU') || data.eu })
      dispatch({ type: CHECKOUT_PAYMENT_RECEIVED, payload: data.orderInfo.paymentType })
    }
    if (data.stateId !== 'changeAddressId') {
      dispatch({ type: CHECKOUT_CHOOSE_SHIPPING, payload: data.shippingList })
      dispatch({ type: GET_PROMOTION_MESSAGE, payload: data.promotionMessage })
    }
    if (data?.checkoutSessionId) {
      dispatch({ type: CHECKOUT_UPDATE_SESSION_ID, payload: data.checkoutSessionId})
    } else {
      dispatch({ type: CHECKOUT_UPDATE_SESSION_ID, payload: '' })
    }
    if (tempFieldObj) {
      dispatch(formUpdateAction(tempFieldObj))
    }
    dispatch(getEasyRefillValid(data))
  }
}

export const toggleLoadingSpinnerAction = (boolean) => (
  {
    type: CHECKOUT_APP_LOADING,
    payload: boolean
  }
)

export const selectAddressFromListAction = data => {
  return dispatch => {
    dispatch({
      type: ADDRESS_SELECT_CHANGE,
      payload: data
    })
  }
}

export const rewardsSuccessAction = data => {
  return dispatch => {
    dispatch(checkoutAlwaysRunAction(data))
    dispatch(toggleLoadingSpinnerAction(false))
  }
}

export const removeRewardsSuccessAction = (data, tempFieldObj) => {
  return dispatch => {
    dispatch(checkoutAlwaysRunAction(data, tempFieldObj))
    dispatch(toggleLoadingSpinnerAction(false))
  }
}