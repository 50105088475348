import React from 'react'
import styled from 'styled-components'

export const FavoriteIcon = ({
  height = '3rem',
  width = '3rem'
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='favorite_border'>
        <Path id='Vector' d='M22.5 3.76666C20.18 3.76666 17.9534 4.84666 16.5 6.55333C15.0467 4.84666 12.82 3.76666 10.5 3.76666C6.39335 3.76666 3.16669 6.99333 3.16669 11.1C3.16669 16.14 7.70002 20.2467 14.5667 26.4867L16.5 28.2333L18.4334 26.4733C25.3 20.2467 29.8334 16.14 29.8334 11.1C29.8334 6.99333 26.6067 3.76666 22.5 3.76666ZM16.6334 24.5L16.5 24.6333L16.3667 24.5C10.02 18.7533 5.83335 14.9533 5.83335 11.1C5.83335 8.43333 7.83335 6.43333 10.5 6.43333C12.5534 6.43333 14.5534 7.75333 15.26 9.58H17.7534C18.4467 7.75333 20.4467 6.43333 22.5 6.43333C25.1667 6.43333 27.1667 8.43333 27.1667 11.1C27.1667 14.9533 22.98 18.7533 16.6334 24.5Z' />
      </g>
    </svg>
  )
}

const Path = styled.path`
  fill: ${props => props.theme.darkestOrange}
`
