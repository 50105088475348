import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { QuantitySelectBox } from '../../_inputs/QuantitySelectBox/QuantitySelectBox'
import { AddToCartButton } from '../../_inputs/AddToCartButton/AddToCartButton'
import { openCartDialogAction } from '../../../actions/cartAction'
import { CTAButtonAlt } from '../../_inputs/Button/Button'
import { addFavoritePDPAction, checkFavorites } from "../../../actions/productDetailActions/productDetailActions"
import { FavoriteIcon } from '../../_svgs/FavoriteIcon/FavoriteIcon'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'
import useAmplitude from '~/hooks/useAmplitude'

export const AddToCartForm = ({
  clearForm,
  updateForm,
  product,
  formState,
  has3rdParty = true,
  showLabel = true,
  showAddToFav = false,
  quantityId,
  styles,
  outerStyles,
  qtyClasses,
  qtyStyles,
  containerStyles = '',
  btnStyles = '',
  showATCBtn = true,
  labelStyles = '',
  includeTracking = false
}) => {
  const formData = {
    form: 'addToCart',
    quantity: '1'
  }

  const dispatch = useDispatch()
  const favoriteState = useSelector(state => state.productDetail ? state.productDetail.favoriteState : '')
  const pdpOGChecked = useSelector(state => state.shared?.ogChecked ? state.shared.ogChecked: false)
  const isLoggedIn = window.initialState ? window.initialState.user.isLoggedIn : false

  const { trackEvent: trackAmplitudeEvent, mapAmplitudeProduct } = useAmplitude();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(checkFavorites(product.productPartNumber))
    }
  }, [product.productPartNumber])

  useEffect(() => {
    clearForm(formData)
  }, [product.productPartNumber])

  useEffect(() => {
    if (window.OG && window.OG?.setQuantity) {
      window.OG.setQuantity({
        id: product.productPartNumber,
        quantity: (formState[formData.form] && formState[formData.form].quantity) || 1,
        module: 'pdp'
      })
    }
  }, [formState[formData.form] && formState[formData.form].quantity])

  const getState = fieldObj => {
    fieldObj.form = formData.form
    updateForm(fieldObj)
    if (fieldObj.value === 'more') {
      updateForm({ form: formData.form, name: 'quantity', value: '6'})
      updateForm({ form: formData.form, name: 'type', value: 'text' })
    }
  }

  const addToFavorite = (prodId) => {
    if (isLoggedIn) {
      trackAmplitudeEvent('product favorited', {
        Product_Engagement: [mapAmplitudeProduct(product)]
      })
      dispatch(addFavoritePDPAction(prodId))
    } else {
      dispatch(sharedSetInstanceAction({
        name: 'addToFavSignIn',
        data: {
          loginHref: `/gateway-login?return_to=/${product.longURL}`,
          registerHref: `/gateway-register?return_to=/${product.longURL}`
        }
      }))
    }
  }

  const additionalAddToCartCode = () => {
    dispatch(openCartDialogAction(product, formState[formData.form] && formState[formData.form].quantity))
    if (window.OG && window.OG.updateCart) {
      window.OG.updateCart([
        {
          id: product.productPartNumber,
          module: 'pdp',
          quantity: formState[formData.form] && formState[formData.form].quantity
        }
      ])
    }
  }

  return (
    <Container data-testid='addToCartForm' styles={styles}>
      <OuterContainer styles={outerStyles}>
        <InnerContainer styles={containerStyles}>
          {showATCBtn &&
            <>
              <QuantitySelectBox
                id={quantityId}
                name='quantity'
                maxQty={product.offerMaxQty}
                returnState={getState}
                value={formState?.[formData.form]?.quantity}
                inputType={formState[formData.form] && formState[formData.form].type}
                showLabel={showLabel}
                qtyClasses={qtyClasses}
                qtyStyles={qtyStyles}
                addFieldStyles={css`
                  width: 5.6rem;
                  margin-bottom: 1.6rem;
                `}
                labelStyles={css`
                  padding: 0rem;
                `}
              />
              {product.offerMaxQty !== '99999' && has3rdParty &&
                <QuantityLimit>Limit: {product.offerMaxQty}</QuantityLimit>
              }
              <AddToCartButton
                product={product}
                additionalAfterAddToCartCode={additionalAddToCartCode}
                quantity={formState[formData.form] && formState[formData.form].quantity}
                displayATCBanner={false}
                ogChecked={pdpOGChecked}
                ageVerifyRequired={(product?.flags?.indexOf('age_verification') > -1) || (product?.flagMap?.age_verification)}
                styles={css`
                  font-weight: 700;
                  font-size: 1.6rem;
                  ${btnStyles};
                `}
                includeTracking={includeTracking}
              />
            </>
          }
        </InnerContainer>
      </OuterContainer>
      {!isLoggedIn && showAddToFav &&
        <ATCFormFavButton
          id='addFavesBtn'
          data-target={product.productPartNumber}
          className='cTest_pdp-signin-fav'
          onClick={() => addToFavorite(product.productPartNumber)}
          data-cnstrc-btn='add_to_wishlist'
        >
          <ButtonContentSpan id='favesSpan' className='add-favorite-trck-signin'>
            <FavoriteIcon />Add to Favorites
          </ButtonContentSpan>
        </ATCFormFavButton>
      }

      {isLoggedIn && showAddToFav && favoriteState === 'false' && !includeTracking &&
        <ATCFormFavButton
          id='addFavesBtn'
          data-target={product.productPartNumber}
          onClick={() => addToFavorite(product.productPartNumber)}
          data-cnstrc-btn='add_to_wishlist'
        >
          <ButtonContentSpan id='favesSpan' className='add-favorite-trck'>
            <FavoriteIcon />Add to Favorites
          </ButtonContentSpan>
        </ATCFormFavButton>
      }
      {isLoggedIn && showAddToFav && favoriteState === 'added' &&
        <ATCFormFavoriteTextDiv className='trck-added-favorites' styles={css`color: ${props => props.theme.primary};`}>Item added to favorites</ATCFormFavoriteTextDiv>
      }
      {has3rdParty && product.srEligible &&
        <SRDiv data-testid='SRDiv' name='sr_productDetailDiv' />
      }
      {isLoggedIn && showAddToFav && favoriteState === 'true' &&
        <ATCFormFavoriteTextDiv styles={css`color: ${props => props.theme.primary};`}>Item in favorites</ATCFormFavoriteTextDiv>
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
  ${props => props.styles};
`

const SRDiv = styled.div`
  margin: 1rem 0;
  width: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.styles};
`

const OuterContainer = styled.section`
  display: flex;
  flex-direction: column;
  ${props => props.styles};
`

const QuantityLimit = styled.p`
  margin: 0 .8rem 1rem 0;
  @media (max-width: 1300px){
    display: flex;
    justify-content: flex-end;
  }
`

export const ATCFormFavoriteTextDiv = styled.div`
  font-size: 1.6rem;
  text-align: center;
  ${props => props.styles}
`

const ATCFormFavButton = styled(CTAButtonAlt)`
  font-size: 1.6rem;
  border: 1px solid ${props => props.theme.darkestOrange};
  border-radius: 0.5rem;
  ${props => props.styles};
`

const ButtonContentSpan = styled.span`
  display: flex;
  align-items: center;
`
