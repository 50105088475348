import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Chevron = ({
  point,
  color = '#00843d',
  classes,
  height = `20px`,
  styles
}) => {
  return (
    <Svg direction='ltr' point={point} styles={styles} className={`chevron img-fluid ${classes}`} chevronHeight={height} viewBox='0 0 12.18 20.12'>
      <title>Chevron</title>
      <polyline fill='none' stroke={color} strokeWidth='3px' points='11.12 19.06 2.12 10.06 11.12 1.06' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  width: 11px;
  height: ${props => props.chevronHeight};
  ${props => props.styles};
  ${props => props.point === 'down' && `
    transform: rotate(270deg);
  `}
    ${props => props.point === 'right' && `
    transform: rotate(180deg);
  `}
    ${props => props.point === 'top' && `
    transform: rotate(90deg);
  `}
  
`
